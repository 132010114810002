import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      className="row not-found-content"
      style={{ minHeight: "40vh", marginTop: "40px" }}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>

      <Link style={{ background: "rgb(32, 114, 194);" }} to="/">
        Home page
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
